//libraries
import React from "react";
import styled from "styled-components";
// helpers
import { translator } from "../../helpers/lang";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { useLang } from "../../context/languageContext";
// static
import image from "../../../static/404/404.svg";

export const Content404 = () => {
  const { lang:langCurrent } = useLang();

  return (
    <StyledContent404>
      <StyledContainer>
        <StyledCol>
          <StyledPretitle
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                es: "¡Ups!",
                en: "Ops!",
              }),
            }}
          />
          <StyledTitle
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                es: "No encontramos la página que&nbsp;buscas.",
                en: "Page not found.",
              }),
            }}
          />
          <StyledSubtitle
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                es: "Es posible que se haya eliminado o movido a otro sitio.<br>¡Lo sentimos!",
                en: "Sorry, we couldn’t find what you were looking for.",
              }),
            }}
          />
          <StyledButton
            href={translator(langCurrent, {
              es: "/es",
              en: "/",
            })}
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                es: "Volver al inicio",
                en: "Back to home",
              }),
            }}
            aria-label={translator(langCurrent, {
              es: "Volver al inicio",
              en: "Back to home",
            })}
          />
        </StyledCol>
        <StyledCol>
          <StyledImage src={image} alt="Not Found" />
        </StyledCol>
        <StyledSubtitleMobile
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              es: "Es posible que se haya eliminado o movido a otro sitio. ¡Lo sentimos!",
              en: "Sorry, we couldn’t find what you were looking for.",
            }),
          }}
        />
        <StyledButtonMobile
          href={translator(langCurrent, {
            es: "/es",
            en: "/",
          })}
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              es: "Volver al inicio",
              en: "Back to home",
            }),
          }}
          aria-label={translator(langCurrent, {
            es: "Volver al inicio",
            en: "Back to home",
          })}
        />
      </StyledContainer>
    </StyledContent404>
  );
};

const StyledContent404 = styled.section`
  position: relative;
  padding-top: 90px;
  background-color: rgb(241, 244, 247);

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    padding-top: 100px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    flex-direction: row;
  }

  @media (min-width: ${BREAKPOINTS.lg + "px"}) {
    max-width: 1220px;
    padding: 72px 16px;
  }

  @media (min-width: ${BREAKPOINTS.xxl + "px"}) {
    padding: 72px 0;
  }
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    width: 50%;
    gap: 40px;
    text-align: left;
  }
`;

const StyledPretitle = styled.div`
  color: #1a1a1a;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.3;

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    font-size: 76px;
  }
`;

const StyledTitle = styled.h1`
  max-width: 300px;
  margin: auto;
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    max-width: unset;
    margin: 0;
    padding-bottom: 0;
    font-size: 24px;
  }
`;

const StyledSubtitle = styled.h2`
  display: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    display: block;
    font-size: 24px;
  }
`;

const StyledSubtitleMobile = styled(StyledSubtitle)`
  display: block;
  padding: 16px 0;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    display: none;
  }
`;

const StyledButton = styled.a`
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.5px;
  background-color: #005eb8;
  color: #fff;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    display: flex;
    max-width: 320px;
  }
`;

const StyledButtonMobile = styled(StyledButton)`
  display: flex;

  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    display: none;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  @media (min-width: ${BREAKPOINTS.md + "px"}) {
    margin-left: auto;
    max-width: 488px;
  }
`;
