// libraries
import React, { useState, useEffect } from "react";
// components
import { HelmetBlock } from "../components/HelmetBlock";
import { Header } from "../components/HeaderBlock";
import { PreFooterBlock } from "../components/PreFooterBlock";
import { FooterBlock } from "../components/FooterBlock";
import { Content404 } from "../components/Content404";
// context
import { LanguageContextProvider } from "../context/languageContext";
// static
import "../styles/main.scss";

const NotFoundPage = () => {
  const [langCurrent, setLangCurrent] = useState("");

  const data = [
    {
      seo: {
        canonical: "",
        metaDesc: "",
        metaRobotsNoindex: "noindex,follow",
        title: "Page not found - ID Finance",
        opengraphTitle: "Page not found - ID Finance",
        opengraphSiteName: "ID Finance",
      },
    },
  ];

  useEffect(() => {
    setLangCurrent(window.location.pathname.includes("/es/") ? "es" : "en");
  }, []);

  return (
    <LanguageContextProvider lang={langCurrent}>
      <HelmetBlock data={data[0].seo} />
      <Header />
      <Content404 />
      <PreFooterBlock />
      <FooterBlock />
    </LanguageContextProvider>
  );
};

export default NotFoundPage;
